.checkbox {
    display: flex;
    align-items: center;
    font-size: 2.13vw;
	font-family: 'aAvanteIntBook';
    color: #fff;
    text-shadow: 8px 6px 18px rgba(0, 0, 0, 0.5);
    @media (min-width: 568px) {
        font-size: 1.17vw;
    }
    @media (min-width: 1025px) {
        font-size: 0.7vw;
    }
    &__input {
        position: absolute;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        border: none;
        background: none;
        &:checked + .checkbox__custom {
            &:after {
                position: absolute;
                display: inline-block;
                content: '';
                width: 2.133vw;
                height: 2.133vw;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
				border-radius: 1px;
                background-color: #e5ca85;
                @media (min-width: 568px) {
                    width: 1.3vw;
                    height: 1.3vw;
                }
                @media (min-width: 1025px) {
                    width: 0.7vw;
                    height: 0.7vw;
                }
            }
        }
    }

    &__custom {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.2vw;
        height: 3.2vw;
        border: 1px solid #999;
		border-radius: 3px;
        vertical-align: middle;
        cursor: pointer;
        @media (min-width: 568px) {
            width: 2.08vw;
            height: 2.08vw;
        }
        @media (min-width: 1025px) {
            width: 1.25vw;
            height: 1.25vw;
        }
    }

    &__label {
        margin-left: 2.133vw;
        @media (min-width: 568px) {
            margin-left: 1.13vw;
        }
    }

    &__link {
        color: #e5ca85;
        text-decoration: none;
    }
	&__error {
		position: absolute;
		top: -2vw;
		padding-left: 5.333vw;
		display: none;
		color: #FF0000;
		font-size: 2vw;
		font-family: 'aAvanteIntBook';
		@media screen and (min-width: 568px) {
			top: -1.2vw;
			padding-left: 3.23vw;
			font-size: 1.1vw;
		}
		@media screen and (min-width: 1025px) {
			top: 1.3vw;
			padding-left: 2.33vw;
			font-size: .7vw;
		}
		&--show {
			display: block;
		}
	}
}
