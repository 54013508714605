@font-face {
	font-family: "Colus";
	src:
		url("../fonts/Colus-Regular.eot") format("eot"),
		url("../fonts/Colus-Regular.woff") format("woff"), /* Modern Browsers */
		url("../fonts/Colus-Regular.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "aAvanteIntBook";
	src:
		url("../fonts/aAvanteIntBook.eot") format("eot"),
		url("../fonts/aAvanteIntBook.woff") format("woff"), /* Modern Browsers */
		url("../fonts/aAvanteIntBook.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}