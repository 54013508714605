@import './_base/reset.scss';
@import './_base/fonts.scss';
@import './checkbox.scss';

* {
    box-sizing: border-box;
}

.main {
    position: relative;
    padding-top: 21.6vw;
    min-height: 100vh;
    width: 100%;
    height: 195.2vw;
    background: url('../images/bg.jpg') no-repeat center 0;
    background-size: cover;
	@media screen and (min-width: 568px) {
		height: 133vw;
		padding-top: 10.28vw;
		background-image: url('../images/bg-ipad.jpg');
	}
	@media screen and (min-width: 1025px) {
		height: auto;
		padding: 1.71vw 0 0 16.56vw;
		background-image: url('../images/bg-desk.jpg');
	}
    &__logo {
        position: absolute;
        top: 4.26vw;
        left: 5.6vw;
        display: block;
        width: 16.26vw;
		@media screen and (min-width: 568px) {
			top: 3.125vw;
			left: 4.55vw;
			width: 11.97vw;
		}
		@media screen and (min-width: 1025px) {
			position: static;
			width: 6.01vw;
		}
        img {
            display: block;
            max-width: 100%;
            width: 100%;
        }
    }

    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'Colus';
		@media screen and (min-width: 1025px) {
			display: none;
		}
        span {
            background: linear-gradient(#d7a64b, #ffe48f, #b2732a);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            filter: drop-shadow(0px 0px 10px #000000);
            &:first-child {
                font-size: 9.6vw;
				@media screen and (min-width: 568px) {
					font-size: 6.9vw;
				}
            }
            &:last-child {
                font-size: 8.53vw;
				@media screen and (min-width: 568px) {
					font-size: 6.11vw;
				}
            }
        }

		&--desk {
			display: none;
			@media screen and (min-width: 1025px) {
				margin-top: 4.29vw;
				display: flex;
				align-items: flex-start;
			}
			span {
				&:nth-child(1) {
					font-size: 4.68vw;
				}
				&:nth-child(2) {
					font-size: 6.32vw;
				}
				&:nth-child(3) {
					font-size: 2.73vw;
				}
			}
		}
    }

    &__buttons {
        margin: 3.46vw auto;
        width: 59.73vw;
        display: flex;
        justify-content: space-between;
		@media screen and (min-width: 568px) {
			margin: 2.21vw auto;
			width: 43.09vw;
		}
		@media screen and (min-width: 1025px) {
			margin: 2.5vw 0 0 0;
			width: 21.53vw;
		}
		
    }

    &__button {
        width: 22.13vw;
        height: 22.13vw;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        cursor: pointer;
		@media screen and (min-width: 568px) {
			width: 16.14vw;
			height: 16.14vw;
		}
		@media screen and (min-width: 1025px) {
			width: 9.45vw;
			height: 9.45vw;
		}
    }

	&__rules {
		position: relative;
		padding-left: 11.2vw;
		@media screen and (min-width: 568px) {
			padding-left: 28.25vw;
		}
		@media screen and (min-width: 1025px) {
			margin-top: 0.85vw;
			padding-left: 0;
		}
	}
}

.blick-v {
    animation: blick-v 4s ease infinite;
    box-shadow: 10px 10px 30px #0a1315,
        -10px -10px 30px rgba(255, 255, 255, 0.03),
}
.blick-t {
    animation: blick-t 4s ease infinite;
    box-shadow: 10px 10px 30px #0a1315,
        0px 0px 30px rgba(255, 255, 255, 0.01),
}

@keyframes blick-v {
    0% {
        background-image: url('../images/viber.png');
    }
    50% {
        background-image: url('../images/viber-2.png');
    }
    100% {
        background-image: url('../images/viber.png');
    }
}
@keyframes blick-t {
    0% {
        background-image: url('../images/tel-2.png');
    }
    50% {
        background-image: url('../images/tel.png');
    }
    100% {
        background-image: url('../images/tel-2.png');
    }
}

.ExoClick {
    position: absolute;
    top: 0;
}
